import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useStaff() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const staff = ref([]);
    const lists = reactive({});


    const getStaff = async (data = {}) => {
        try {
            let response = await axios.post('/api/staff/get', data);
            staff.value = response.data.data.staff;
            lists.roles = response.data.data.roles;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getSingleStaff = async (id) => {
        try {
            let response = await axios.get('/api/staff/' + id);
            staff.value = response.data.data.staff;
            lists.roles = response.data.data.roles;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const createStaff = async () => {
        errors.value = ''; //
        try {
            let response = await axios.get('/api/staff/create');
            lists.roles = response.data.data.roles;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const storeStaff = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/staff/', data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteStaff = async(id) => {
        try {
            await axios.delete('/api/staff/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    } 


    const updateStaff = async(data, id) => {
        errors.value = ''; //
        try {
            data.value._method = 'PATCH';
            let response = await axios.post('/api/staff/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }
    
    
    const updateStaffPassword = async(data, id) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/staff/update_password/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }



    return {
        staff,
        lists,
        msgs,
        errors,
        getStaff,
        createStaff,
        storeStaff,
        getSingleStaff,
        deleteStaff,
        updateStaff,
        updateStaffPassword
    }
}